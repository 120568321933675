import React from 'react'
// import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion'
import './ContactUs.css'
import { FaInstagram, FaFacebook } from "react-icons/fa";
// import { useState, useRef } from 'react';

const ContactUs = () => {

    // const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    // const [message, setMessage] = useState('');

    // const handleChange = (event) => {
    //     if (event.target.id === 'contact-name') {
    //         setName(event.target.value);
    //     }

    //     if (event.target.id === 'contact-email') {
    //         setEmail(event.target.value);
    //     }

    //     if (event.target.id === 'contact-message') {
    //         setMessage(event.target.value);
    //     }
    // }

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     console.log('submitted');
    //     console.log(form.current);
    //     emailjs.sendForm('service_zxh04oq', 'template_xv8ia5f', form.current, 'kXw0NdGgirzTU-emK')
    //         .then((result) => {
    //             console.log(result.text);
    //         }, (error) => {
    //             console.log(error.text);
    //         });
    // }

    const titleAnimateVariants = {
        initial: {
            opacity: 0,
            y: 100,
        },

        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 3,
                type: 'spring'
            }
        },

    }

    const addressAnimateVariants = {
        initial: {
            opacity: 0,
            y: 100,
        },

        animate: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.3,
                duration: 1,
            }
        },

    }

    const socialLinksVariants = {
        initial: {
            opacity: 0,
            y: 100,
        },

        animate: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.6,
                duration: 1,
            }
        },

    }

    // const contactFormVariants = {
    //     initial: {
    //         opacity: 0,
    //         x: 30
    //     },

    //     animate: {
    //         opacity: 1,
    //         x: 0
    //     }
    // }

    // const form = useRef();

    return (
        <div className='section' id='contact-us'>
            <div className='contact-us content'>
                <div className='contact-address-details'>
                    <motion.div
                        className='title contact-us-title'
                        variants={titleAnimateVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{ once: false }}
                    >
                        Contact Us
                    </motion.div>

                    <motion.div className='address'
                        variants={addressAnimateVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{ once: false }}
                    >
                        MindForm Architects and Interior Designers, <br />
                        E022, Embassy Residency, <br />
                        Cheran Nagar, Perumbakkam <br />
                        Chennai - 600100 <br /><br />
                        Ph: +91 89399 58099

                    </motion.div>

                    <motion.div className='social-media'
                        variants={socialLinksVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{ once: false }}
                    >
                        <a href="https://www.instagram.com/mindformarchitects/" target="_blank" rel="noreferrer">
                            <FaInstagram size={42} className='instagram-icon' />
                        </a>
                        <a href="https://www.facebook.com/mindform.architects.and.interior.designers" target="_blank" rel="noreferrer">
                            <FaFacebook size={42} className='facebook-icon' />
                        </a>
                    </motion.div>
                </div>
                {/* <div className='contact-form'>
                    <form className='form-wrapper' onSubmit={handleSubmit} ref={form}>
                        <motion.div className='form-row'
                            variants={contactFormVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{ margin: "-20% 0px 0px 0px", once: false }}
                            transition={{ duration: 1, delay: 0.2 }}
                        >
                            <input type='text' onChange={handleChange} id='contact-name' value={name} placeholder='Enter your name' name="user_name" />
                        </motion.div>

                        <motion.div className='form-row'
                            variants={contactFormVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{ margin: "-20% 0px 0px 0px", once: false }}
                            transition={{ duration: 1, delay: 0.4 }}
                        >
                            <input type='email' onChange={handleChange} id='contact-email' value={email} placeholder='Enter your email id' name="user_email" />
                        </motion.div>

                        <motion.div className='form-row'
                            variants={contactFormVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{ margin: "-20% 0px 0px 0px", once: false }}
                            transition={{ duration: 1, delay: 0.6 }}
                        >
                            <textarea type='text' onChange={handleChange} id='contact-message' value={message} placeholder='Enter your message' name="message" />
                        </motion.div>
                        <motion.div className='form-row'
                            variants={contactFormVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{ margin: "0% 0px 0px 0px", once: false }}
                            transition={{ duration: 1, delay: 0.8 }}
                        >
                            <button type='submit' className='form-submit-btn'>Submit</button>
                        </motion.div>
                    </form>
                </div> */}
            </div>
        </div>
    )
}

export default ContactUs