import React from 'react'
import { details } from './Details'
import Card from '../Card/Card'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { Link } from 'react-router-dom'
import './Project.css'

const Projects = () => {

    const cardClickHandler = (id) => {
        console.log(id)
    }
    const totalProjects = details.length;
    const [projectNumber, setProjectNumber] = useState(0);

    const nextButtonHandler = () => {
        if (projectNumber < totalProjects - 2)
            setProjectNumber(projectNumber + 1);
    }

    const previousButtonHandler = () => {
        if (projectNumber > 0)
            setProjectNumber(projectNumber - 1);
    }

    return (
        <div className='section' id='projects'>
            <div className='projects content'>
                <div className='title'>Projects</div>
                <div
                    className='cards-container'
                >
                    <motion.div
                        className='cards-scroll'
                        initial={{ x: 0 }}
                        animate={{ x: `-${51 * projectNumber}em`, transition: { duration: 0.5, type: 'spring' } }}
                    >
                        {details.map((item, index) => <Card details={item} index={index} cardClickHandler={cardClickHandler} key={index} />)}
                    </motion.div>
                </div>
                <div className='projects-footer'>
                    <Link to={`/projects`} style={{textDecoration: 'none'}}>
                        <button className='view-projects-button'>
                            View all projects
                        </button>
                    </Link>
                    <div className='arrow-navs'>
                        <div className='arrow-left' onClick={previousButtonHandler}>
                            <FiArrowLeftCircle size={42} />
                        </div>
                        <div className='arrow-right' onClick={nextButtonHandler}>
                            <FiArrowRightCircle size={42} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects