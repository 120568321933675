import React from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from './Components/LandingPage/LandingPage';
import ProjectPage from './Components/MasterProjectPage/MasterProjectPage';
import SingleProjectPage from './Components/SingleProjectPage/SingleProjectPage';
import './App.css'

const App = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <LandingPage />,
        },

        {
            path: "/projects",
            element: <ProjectPage />
        },

        {
            path: "/projects/:id",
            element: <SingleProjectPage />
        }
    ]);

    return (
        <RouterProvider router={router} />
    )
}

export default App