import React from 'react'
import { Link } from 'react-scroll'
import { motion } from 'framer-motion'

import './SideBar.css'

const SideBar = ({ optionClickHandler }) => {

    const sections = [
        {
            id: 'home',
            title: 'Home'
        },
        {
            id: 'projects',
            title: 'Portfolio'
        },
        {
            id: 'about-the-founder',
            title: 'About the Founder'
        },
        {
            id: 'contact-us',
            title: 'Contact Us'
        }
    ]

    const sidebarAnimateProps = {
        initital: {
            x: '100vw',
            opacity: 0
        },

        animate: {
            x: 0,
            opacity: 1,

            transition: {
                duration: 1,
            }
        },

        exit: {
            x: '100vw',
            transition: {
                duration: 0.5
            },
            opacity: 0
        }
    }

    // const listAnimateProps = {
    //     initial: {
    //         opacity: 0,
    //         transition: {
    //             delay: 0.1,
    //             staggerChildren: 2
    //         }
    //     },

    //     animate: {
    //         opacity: 1,

    //         transition: {
    //             delay: 0.1,
    //             staggerChildren: 2
    //         }
    //     }
    // }

    return (
        <motion.div className='sidebar'
            variants={sidebarAnimateProps}
            initial='initial'
            animate='animate'
            exit='exit'

        >
            <div className='sidebar-contents'>
                <motion.ul
                // variants={listAnimateProps}
                // initial='initial'
                // animate='animate'
                >
                    {
                        sections.map((section) => {
                            return <li key={section.id}> <Link to={section.id} spy={true} smooth={'easeInOutQuart'} duration={1500} id={`${section.id}-link`} onClick={optionClickHandler}> {section.title} </Link></li>
                        })
                    }
                </motion.ul>
            </div>
        </motion.div>
    )
}

export default SideBar