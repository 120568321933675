import React from 'react'
import './AboutFounder.css'

const AboutFounder = () => {
    return (
        <div className='founder-page' id='about-the-founder'>
            <div className='title'>
                About the Founder
            </div>
            <div className='founder-content'>
                <div className='founder-image-container'>
                    <img src='/Images/Profile/ProfilePic.jpg' alt='founder' className='founder-image' />
                </div>

                <div className='founder-text-container'>
                    <div className='founder-text-title'>
                        Preme Chidambaram
                    </div>
                    <div className='founder-text'>
                        <p>With a solid foundation in architecture from SRM University, Chennai, and master's in Project Management from the prestigious National University of Singapore (NUS), Preme honed his skills and expertise through enriching experiences in renowned architectural firms such as <em>RSP Architects in Singapore, Design Works in Chennai, Cushman and Wakefield in India, as well as engagements with Landmark Group and Yum Restaurants.</em></p>
                        <p>Throughout his illustrious career spanning <em>over 20 years</em>, Preme has been instrumental in delivering exceptional projects for esteemed retail brands including <em>Sony, Titan, Cisco, Max, KFC, and Khazana Jewellers.</em></p>
                        <p>Driven by a desire to explore the emotional connectivity of spaces, our founder envisioned a <em>platform where design transcends mere aesthetics to touch the hearts of those who inhabit them.</em> Thus, with a wealth of experience and a fervent ambition, he embarked on the journey of entrepreneurship to establish <em>MindForm Architects.</em></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFounder