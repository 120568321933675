import React from 'react'
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion'
// import Card from '../Card/Card';
import './MasterProjectPage.css'
import { details } from '../Projects/Details';
import Card from '../MasterProjectCard/Card';

const ProjectPage = (props) => {
    const [filter, setFilter] = useState('all');
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (filter === 'all') {
            setItems(details);
            return;
        }
        const filtered = details.filter((item) => item.type === filter);
        setItems(filtered);
    }, [filter])
    return (
        <motion.div
            initial={{ opacity: 1 }}
            // whileInView={{ opacity: 1 }}
            id='projects-page'
            className='master-project-page'
        >
            <div className='master-project-page-title'>
                {/* Portfolio */}
            </div>
            <div className='project-filters'>
                <ul className='filter-list'>
                    <li><button className={filter === 'all' ? 'active' : ''} onClick={() => setFilter('all')}>All</button></li>
                    <li><button className={filter === 'restobar' ? 'active' : ''} onClick={() => setFilter('restobar')}>Restaurants and Restobars</button></li>
                    <li><button className={filter === 'residential' ? 'active' : ''} onClick={() => setFilter('residential')}>Residential Interiors</button></li>
                    <li><button className={filter === 'commercial' ? 'active' : ''} onClick={() => setFilter('commercial')}>Office Spaces</button></li>
                    <li><button className={filter === 'hospitality' ? 'active' : ''} onClick={() => setFilter('hospitality')}>Hospitality</button></li>
                </ul>
            </div>
            <motion.div className='masterProject-page-cards-container' layout>
                {items.map((item, index) => <Card details={item} index={index} key={index} />)}
            </motion.div>
        </motion.div>
    )
}

export default ProjectPage