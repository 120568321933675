import React from 'react'
import { motion } from 'framer-motion'
import './Card.css'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
const Card = ({ details, index, cardClickHandler }) => {

    const ref = useRef(null);
    const [numCards, setNumCards] = useState(0)

    useEffect(() => {
        setNumCards(Math.floor(window.innerWidth / ref.current.offsetWidth))
    }, [])

    const cardDetailsAnimateProps = {
        initial: {
            opacity: 0,
        },

        animate: {
            opacity: 1,
        }
    }

    return (
        <Link to={`/projects/${details.id}`} state={{ projectId: details.id }} >
            <motion.div
                className='card-container'
                initial={{ opacity: 0, scale: 1, zIndex: 1 }}
                whileInView={{ opacity: 1, transition: { delay: index < numCards ? index * 0.2 : 0.2 } }}
                // whileHover={{ scale: 1.05, zIndex: 98 }}
                viewport={{ margin: "-20% 0px 0px 0px", once: false }}
                ref={ref}
            >
                <motion.img
                    className='card-pic-landing'
                    src={details.images[0]}
                    alt='aesthetic lobby'
                />

                <motion.div
                    className='card-content'
                    variants={cardDetailsAnimateProps}
                    initial='initial'
                    whileHover='animate'
                >
                    <div>
                        {details.projectName}
                    </div>
                    <div style={{ paddingTop: '1em' }}>
                        {details.location}
                    </div>
                </motion.div>

                <div className='card-details'>
                    <div>
                        {details.projectName}
                    </div>
                </div>
            </motion.div>
        </Link >
    )
}

export default Card