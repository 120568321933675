import React from 'react'
import { details } from '../Projects/Details';
import { useParams } from 'react-router-dom'
import { Masonry } from '@mui/lab';
import './SingleProjectPage.css'

const SingleProjectPage = () => {

    const { id } = useParams()
    let projectDetails = {}
    details.forEach(project => {
        //eslint-disable-next-line
        if (project.id == id) {
            projectDetails = project
        }
    })

    const clickHandler = (path) => {
        console.log('card has been clicked')
        window.open(path, '_blank');
    }

    return (
        <div className='single-project-page'>
            <h1 className='project-name'>{projectDetails.projectName}</h1>
            {/* <h3>About the Project</h3>
            <p className='project-details-single-project-page'>
                {projectDetails.aboutProject}
            </p> */}
            <Masonry columns={{ xs: 1, sm: 4 }} spacing={2}>
                {
                    // projectDetails.images.map((path, index) => <a href={path} target='_blank' rel="noreferrer"><img src={path} alt='pic' key={index} onClick={clickHandler} /></a>) -> Not working as it messes up the masonry layout
                    projectDetails.images.map((path, index) => <img src={path} alt='pic' key={index} onClick={() => clickHandler(path)} />)
                }
            </Masonry>
        </div>
    )
}

export default SingleProjectPage