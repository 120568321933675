import React from 'react'
import { Link } from 'react-scroll'
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
import './NavBar.css'

const NavBar = ({ hamburgerClickHandler, isOpen }) => {

    const sections = [
        {
            id: 'home',
            title: 'Home'
        },
        {
            id: 'projects',
            title: 'Portfolio'
        },
        {
            id: 'about-the-founder',
            title: 'About the Founder'
        },
        {
            id: 'contact-us',
            title: 'Contact Us'
        }
    ]

    return (
        <div className='navbar' id='navbar'>
            <div className='navbar-title'>
                <Link to='home' spy={true} smooth={'easeInOutQuart'} duration={1500} id={'home-link'}>
                    MindForm
                </Link>
            </div>
            <ul>
                {
                    sections.map((section) => {
                        return <li key={section.id}> <Link to={section.id} spy={true} smooth={'easeInOutQuart'} duration={1500} id={`${section.id}-link`}> {section.title} </Link></li>
                    })
                }
            </ul>

            <div className='navbar-hamburger'>
                {
                    !isOpen && <RxHamburgerMenu size={30} onClick={hamburgerClickHandler} />
                }
                {
                    isOpen && <RxCross1 size={30} onClick={hamburgerClickHandler} />
                }
            </div>
        </div>
    )
}

export default NavBar