import React from 'react'
import { motion } from 'framer-motion'
import './Card.css'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
const Card = ({ details, index, cardClickHandler }) => {

    const ref = useRef(null);

    return (
        <motion.div layout className='master-card-container'>
            <p className='master-project-card-name'>
                {details.projectName}
            </p>
            <Link to={`/projects/${details.id}`} state={{ projectId: details.id }} className='project-link'>
                <div
                    className='master-card-container'
                    ref={ref}
                >
                    <img
                        className='card-pic'
                        src={details.images[0]}
                        alt='aesthetic lobby'
                    />
                </div>
            </Link >
        </motion.div>
    )
}

export default Card