import React from 'react'
import NavBar from '../Navbar/NavBar'
import Projects from '../Projects/Projects'
import ContactUs from '../ContactUs/ContactUs'
import HomePage from '../HomePage/HomePage'
import AboutFounder from '../AboutFounder/AboutFounder'
import SideBar from '../SideBar/SideBar'
import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import './LandingPage.css'

const LandingPage = () => {

    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const handleMenuClick = () => {
        setMenuIsOpen(!menuIsOpen)
    }

    const handleOptionClick = () => {
        setMenuIsOpen(!menuIsOpen)
    }

    return (
        <div>
            <NavBar hamburgerClickHandler={handleMenuClick} isOpen={menuIsOpen} />
            <AnimatePresence>
                {menuIsOpen && <SideBar optionClickHandler={handleOptionClick} />}
            </AnimatePresence>
            <HomePage />
            <Projects />
            <AboutFounder />
            <ContactUs />
        </div>
    )
}

export default LandingPage