export const details = [

    {
        id: 1,
        projectName: 'Charlys',
        folderName: 'Charlys',
        location: 'Chennai',
        details: 'Restaurant design for charly\'s',
        type: 'restobar',
        images: [
            '/Images/Charlys/Exterior.jpg',
        ],
        aboutProject: "Lorem ipsum odor amet, consectetuer adipiscing elit. Nullam urna lobortis consequat, netus faucibus nulla maecenas ridiculus. Efficitur cras platea purus quisque mi augue efficitur sem pulvinar. Porta tincidunt ultricies consectetur nisl nascetur curae malesuada elementum. Aliquet mus adipiscing consequat magna blandit scelerisque pretium. Auctor integer mollis class a sociosqu in. Non vel sociosqu ultrices aliquam amet conubia. Nostra magna elit parturient morbi sapien suspendisse cras montes. Mauris nam vivamus primis tortor non porta cursus lacinia. Conubia commodo senectus faucibus; cursus senectus ipsum quisque accumsan.",
    },

    {
        id: 2,
        projectName: 'Cal\'s Office',
        folderName: 'Cals_Office',
        location: 'Chennai',
        details: 'Office space for Cal\'s',
        type: 'commercial',
        images: [
            '/Images/Cals_Office/reception_render.jpeg',
            '/Images/Cals_Office/reception_7.jpeg',
            // '/Images/Cals_Office/MD_Office_render.jpeg',
            // '/Images/Cals_Office/assistant_desk.jpeg',
            // '/Images/Cals_Office/entrance_1.jpeg',
            '/Images/Cals_Office/entrance_2.jpeg',
            // '/Images/Cals_Office/MD_Office_2.jpeg',
            '/Images/Cals_Office/MD_Office_3.jpeg',
            // '/Images/Cals_Office/MD_Office_4.jpeg',
            // '/Images/Cals_Office/MD_Office_5.jpeg',
            // '/Images/Cals_Office/meeting_room.jpeg',
            '/Images/Cals_Office/meeting_room_render.jpeg',
            // '/Images/Cals_Office/reception_1.jpeg',
            // '/Images/Cals_Office/reception_2.jpeg',
            // '/Images/Cals_Office/reception_3.jpeg',
            // '/Images/Cals_Office/reception_5.jpeg',
            // '/Images/Cals_Office/reception_6.jpeg',
            // '/Images/Cals_Office/reception_7.jpeg',
            // '/Images/Cals_Office/reception_8.jpeg',
            // '/Images/Cals_Office/reception_9.jpg',
            // '/Images/Cals_Office/workstation.jpeg',
            '/Images/Cals_Office/workstation_render_1.jpeg',
            // '/Images/Cals_Office/workstation_render_2.jpg',
            // '/Images/Cals_Office/workstation_render_3.jpg',
            '/Images/Cals_Office/workstation_render_4.jpg',
            // '/Images/Cals_Office/workstation_render_5.jpg',
        ],
        aboutProject: "Lorem ipsum odor amet, consectetuer adipiscing elit. Nullam urna lobortis consequat, netus faucibus nulla maecenas ridiculus. Efficitur cras platea purus quisque mi augue efficitur sem pulvinar. Porta tincidunt ultricies consectetur nisl nascetur curae malesuada elementum. Aliquet mus adipiscing consequat magna blandit scelerisque pretium. Auctor integer mollis class a sociosqu in. Non vel sociosqu ultrices aliquam amet conubia. Nostra magna elit parturient morbi sapien suspendisse cras montes. Mauris nam vivamus primis tortor non porta cursus lacinia. Conubia commodo senectus faucibus; cursus senectus ipsum quisque accumsan.",
    },

    {
        id: 3,
        projectName: 'Gowri\'s Residence',
        folderName: 'Gowri_Residence',
        location: 'SAF Games Village, Chennai',
        details: 'Complete interior work for Gowri Residence',
        type: 'residential',
        images: [
            '/Images/Gowri_Residence/living_room_rensder_2.jpeg',
            '/Images/Gowri_Residence/living_room.jpeg',
            // '/Images/Gowri_Residence/living_room_3.jpeg',
            // '/Images/Gowri_Residence/living_room_2.jpeg',
            '/Images/Gowri_Residence/living_room_rensder_1.jpeg',
            // '/Images/Gowri_Residence/living_room_4.jpeg',
            '/Images/Gowri_Residence/swamy_room_1.jpeg',
            // '/Images/Gowri_Residence/swamy_room_2.jpeg',
            '/Images/Gowri_Residence/kavya_room.jpeg',
        ],
        aboutProject: "Lorem ipsum odor amet, consectetuer adipiscing elit. Nullam urna lobortis consequat, netus faucibus nulla maecenas ridiculus. Efficitur cras platea purus quisque mi augue efficitur sem pulvinar. Porta tincidunt ultricies consectetur nisl nascetur curae malesuada elementum. Aliquet mus adipiscing consequat magna blandit scelerisque pretium. Auctor integer mollis class a sociosqu in. Non vel sociosqu ultrices aliquam amet conubia. Nostra magna elit parturient morbi sapien suspendisse cras montes. Mauris nam vivamus primis tortor non porta cursus lacinia. Conubia commodo senectus faucibus; cursus senectus ipsum quisque accumsan.",
    },

    {
        id: 4,
        projectName: 'Honey Spice',
        folderName: 'Honey_Spice',
        location: 'Chennai',
        details: 'Complete interior and exterior design for Honey Spice',
        type: 'restobar',
        images: [
            '/Images/Honey_Spice/exterior.jpeg',
            '/Images/Honey_Spice/bar_render_1.jpeg',
            '/Images/Honey_Spice/bar_render_2.jpeg',
            '/Images/Honey_Spice/outer_bar_render_1.jpeg',
            '/Images/Honey_Spice/outer_bar_render_2.jpeg',
            '/Images/Honey_Spice/eating_area_render.jpg',
        ],
        aboutProject: "Lorem ipsum odor amet, consectetuer adipiscing elit. Nullam urna lobortis consequat, netus faucibus nulla maecenas ridiculus. Efficitur cras platea purus quisque mi augue efficitur sem pulvinar. Porta tincidunt ultricies consectetur nisl nascetur curae malesuada elementum. Aliquet mus adipiscing consequat magna blandit scelerisque pretium. Auctor integer mollis class a sociosqu in. Non vel sociosqu ultrices aliquam amet conubia. Nostra magna elit parturient morbi sapien suspendisse cras montes. Mauris nam vivamus primis tortor non porta cursus lacinia. Conubia commodo senectus faucibus; cursus senectus ipsum quisque accumsan.",
    },

    {
        id: 5,
        projectName: 'Venugopal\'s Residence',
        folderName: 'Venugopal_Residence',
        location: 'Chennai',
        details: 'Complete interior design for Venugopal\'s Residence',
        type: 'residential',
        images: [
            '/Images/Venugopal_Residence/entrance_render.jpeg',
            // '/Images/Venugopal_Residence/entrance_1.jpeg',
            // '/Images/Venugopal_Residence/entrance_2.jpeg',
            // '/Images/Venugopal_Residence/ceiling.jpeg',
            '/Images/Venugopal_Residence/swamy_room.jpeg',
            '/Images/Venugopal_Residence/swamy_room_entrance.jpeg',
            '/Images/Venugopal_Residence/swamy_room_render.jpeg',
            // '/Images/Venugopal_Residence/swamy-room_2.jpeg',
            '/Images/Venugopal_Residence/tv_unit.jpeg',
            '/Images/Venugopal_Residence/tv_unit_render.jpeg',

        ],
        aboutProject: "Lorem ipsum odor amet, consectetuer adipiscing elit. Nullam urna lobortis consequat, netus faucibus nulla maecenas ridiculus. Efficitur cras platea purus quisque mi augue efficitur sem pulvinar. Porta tincidunt ultricies consectetur nisl nascetur curae malesuada elementum. Aliquet mus adipiscing consequat magna blandit scelerisque pretium. Auctor integer mollis class a sociosqu in. Non vel sociosqu ultrices aliquam amet conubia. Nostra magna elit parturient morbi sapien suspendisse cras montes. Mauris nam vivamus primis tortor non porta cursus lacinia. Conubia commodo senectus faucibus; cursus senectus ipsum quisque accumsan.",
    },

    {
        id: 6,
        projectName: 'Digibionic',
        folderName: 'Digibionic',
        location: 'Chennai',
        details: 'Interior Design for Digibionic office',
        type: 'commercial',
        images: [
            '/Images/Digibionic/hall_render.jpg',
            '/Images/Digibionic/entrance_render.jpg',
            '/Images/Digibionic/work_area_2.jpg',
            '/Images/Digibionic/work_area_1.jpg',
        ],
        aboutProject: "Lorem ipsum odor amet, consectetuer adipiscing elit. Nullam urna lobortis consequat, netus faucibus nulla maecenas ridiculus. Efficitur cras platea purus quisque mi augue efficitur sem pulvinar. Porta tincidunt ultricies consectetur nisl nascetur curae malesuada elementum. Aliquet mus adipiscing consequat magna blandit scelerisque pretium. Auctor integer mollis class a sociosqu in. Non vel sociosqu ultrices aliquam amet conubia. Nostra magna elit parturient morbi sapien suspendisse cras montes. Mauris nam vivamus primis tortor non porta cursus lacinia. Conubia commodo senectus faucibus; cursus senectus ipsum quisque accumsan.",
    },

    {
        id: 7,
        projectName: 'Sriram JB Residency',
        folderName: 'Sriram_JB_Residency',
        location: 'Virudhunagar',
        details: 'Interior Design for Sriram JB Residency',
        type: 'hospitality',
        images: [
            '/Images/Sriram_JB_Residency/Interior.jpg',
        ],
        aboutProject: "Lorem ipsum odor amet, consectetuer adipiscing elit. Nullam urna lobortis consequat, netus faucibus nulla maecenas ridiculus. Efficitur cras platea purus quisque mi augue efficitur sem pulvinar. Porta tincidunt ultricies consectetur nisl nascetur curae malesuada elementum. Aliquet mus adipiscing consequat magna blandit scelerisque pretium. Auctor integer mollis class a sociosqu in. Non vel sociosqu ultrices aliquam amet conubia. Nostra magna elit parturient morbi sapien suspendisse cras montes. Mauris nam vivamus primis tortor non porta cursus lacinia. Conubia commodo senectus faucibus; cursus senectus ipsum quisque accumsan.",
    },

    {
        id: 8,
        projectName: 'Vikaan Shelters Model Villa',
        folderName: 'Vikaan_Shelters',
        location: 'Ponmar',
        details: 'Complete interior design for Villa in Vikaan Shelters',
        type: 'residential',
        images: [
            '/Images/Vikaan_Shelters/living_room_2.jpg',
            '/Images/Vikaan_Shelters/bedroom_1.jpg',
            '/Images/Vikaan_Shelters/bedroom_2.jpg',
            '/Images/Vikaan_Shelters/bedroom_3.jpg',
            '/Images/Vikaan_Shelters/kitchen.jpg',
            '/Images/Vikaan_Shelters/kitchen_2.jpg',
            '/Images/Vikaan_Shelters/living_room.jpg',
            '/Images/Vikaan_Shelters/living_room_3.jpg',
        ],
        aboutProject: "Lorem ipsum odor amet, consectetuer adipiscing elit. Nullam urna lobortis consequat, netus faucibus nulla maecenas ridiculus. Efficitur cras platea purus quisque mi augue efficitur sem pulvinar. Porta tincidunt ultricies consectetur nisl nascetur curae malesuada elementum. Aliquet mus adipiscing consequat magna blandit scelerisque pretium. Auctor integer mollis class a sociosqu in. Non vel sociosqu ultrices aliquam amet conubia. Nostra magna elit parturient morbi sapien suspendisse cras montes. Mauris nam vivamus primis tortor non porta cursus lacinia. Conubia commodo senectus faucibus; cursus senectus ipsum quisque accumsan.",
    },

]