import React from 'react'
import { motion } from 'framer-motion'
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import LogoSvg from './LogoSvg';
import { useState, useEffect } from 'react';
// import { LoremIpsum } from 'react-lorem-ipsum';
import { imagesPaths } from './Images'
import './HomePage.css'

const titleAnimateVariants = {
    initial: {
        opacity: 0,
        x: '-20em',
    },

    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 2,
            type: 'spring'
        }
    }
}

const HomePage = () => {
    const [imgIndex, setImgIndex] = useState(0);
    const handleNextClick = () => {
        setImgIndex((prev) => (prev + 1) % imagesPaths.length)
    }

    const handlePrevClick = () => {
        if (imgIndex > 0)
            setImgIndex((prev) => prev - 1)
        else
            setImgIndex(imagesPaths.length - 1)
    }

    useEffect(() => {
        setInterval(() => handleNextClick(), 8000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='section' id='home'>
            <div className='homepage-overlay'>
                <div className='images-container'>
                    <motion.div
                        className='images-scroll'
                        initial={{ x: '100vw' }}
                        animate={{ x: `-${100 * imgIndex}vw`, transition: { duration: 1.3, type: 'spring', bounce: 0.16 } }}
                    >
                        {
                            imagesPaths.map((path, index) => {
                                return <motion.img
                                    key={index}
                                    src={`${process.env.PUBLIC_URL}${imagesPaths[index]}`} alt='background'
                                    className='homepage-background'
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1, transition: { duration: 1 } }}
                                />
                            })
                        }
                    </motion.div>
                </div>
                <div className='home-page content'>
                    <div className='homepage-splitter background-blur'>
                        <LogoSvg />

                        <motion.div
                            className='about-us'
                        >
                            <motion.div
                                // className='background-blur'
                                variants={titleAnimateVariants}
                                initial="initial"
                                animate="animate"
                            >
                                <h1 className='homepage-content-title'>Mindform Architects and Interior Designers</h1>
                                <p className='homepage-about-us'>
                                    <p>
                                        At MindForm, we understand that spaces possess the power to evoke emotions, influencing those who interact with them. Our philosophy revolves around this emotional sensitivity, driving our approach to design. We believe in crafting spaces that connect with individuals on a profound level, ensuring they resonate authentically.
                                    </p>
                                    <p>
                                        MindForm Architects was founded with a vision to offer world-class design solutions that prioritize emotional connectivity and purposeful design. We blend expertise with a personal touch, curating spaces that seamlessly integrate with their environments. Our commitment to excellence ensures that each project is meticulously tailored to our clients' needs, delivering perfection with every design.
                                    </p>
                                    <p>
                                        Experience the difference of MindForm Architects, where world-class standards meet a deep understanding of emotional spaces, perfectly curated for you.
                                    </p>
                                </p>
                            </motion.div>
                        </motion.div>
                        <div className='homepage-arrows-container'>
                            <div className='arrow-left' onClick={handlePrevClick}>
                                <FiArrowLeftCircle size={42} />
                            </div>
                            <div className='arrow-right' onClick={handleNextClick}>
                                <FiArrowRightCircle size={42} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage